.search-page-layout-v3 {
	background-color: var(--color-white);
	margin-top: var(--page-margin-top);

	.inner-banner {
		&.utersum {
			background-image: url(RESOURCE/img/banner-vermieter.jpg);
			margin-bottom: 50px;
		}
	}

	.testimonial-slide {
		position: relative;

		.item {
			background-color: rgba(246, 247, 247, 1);
			padding: 30px;

			.icon-img-bl {
				width: 70px;
				height: 70px;
				display: block;
			}

			@media (min-width:1201px) {
				height: 380px;
			}
		}

		.owl-prev {
			position: absolute;
			top: 30%;
			left: -40px;

			@media (max-width:767px) {
				left: 0;
			}

			span {
				font-size: 80px;
			}
		}

		.owl-next {
			position: absolute;
			top: 30%;
			right: -40px;

			@media (max-width:767px) {
				right: 0;
			}

			span {
				font-size: 80px;
			}
		}

		.icon {
			background-repeat: no-repeat;
			background-position: center;

			&.icon-quote {
				background-image: url(RESOURCE/img/icon-quote.svg);
				width: 37px;
				height: 37px;
				display: inline-block;
				margin-bottom: 20px;
			}
		}
	}



	.results {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 30px;

		@media (min-width:1200px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
		}

		/*	@media (max-width:992px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
		} */

		@media (max-width:992px) {
			grid-template-columns: 1fr;
		}

	}

	.advantages {
		position: relative;
		padding-top: 50px;

		.haf-divider {
			content: "";
			position: absolute;
			top: 50px;
			left: 50%;
			width: 80px;
			height: 2px;
			background-color: #000;
		}
	}


	.more-btn-panel {
		margin-top: 30px;
	}

	.container,
	.container-xl {
		@media (min-width:1200px) {
			/*max-width: 1370px; */
		}

		@media (min-width:1500px) {
			max-width: 1370px;
		}
	}

}



.region-bl {
	.h3 {
		background-color: var(--btn-primary-bg-color);
		color: var(--color-white);
		font-size: 16px;
		text-align: center;
		padding: 10px 15px;
		display: block;
		text-decoration: none;
	}

	.half-region {
		.region-img {
			@media (min-width: 992px) {
				height: 263px !important;
			}
		}
	}

	.region-box {
		margin-bottom: 30px;

		.region-img {
			width: 100%;
			height: 200px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;

			&.region-8136 {
				background-image: url(RESOURCE/img/region-8136.jpg);
			}

			&.region-8126 {
				background-image: url(RESOURCE/img/region-8126.jpg);
			}

			&.region-8127,
			&.region-8130 {
				background-image: url(RESOURCE/img/region-8127.jpg);
			}

			&.region-8129 {
				background-image: url(RESOURCE/img/region-8129.jpg);
			}

			&.region-8131 {
				background-image: url(RESOURCE/img/region-8131.jpg);
			}


			&.region-8133 {
				background-image: url(RESOURCE/img/region-8132.jpg);
			}

			&.region-8134,
			&.region-8125 {
				background-image: url(RESOURCE/img/region-8134.jpg);
			}

			&.region-8135,
			&.region-8128 {
				background-image: url(RESOURCE/img/region-8135.jpg);
			}

			&.region-8132 {
				background-image: url(RESOURCE/img/region-utersum.jpg);
			}


			&.region-8138,
			&.region-8137,
				{
				background-image: url(RESOURCE/img/region-8138.jpg);
			}

			&.region-8139 {
				background-image: url(RESOURCE/img/region-8139.jpg);
			}

			&.region-9470 {
				background-image: url(RESOURCE/img/region-9470.jpg);
			}
		}



	}
}

.my-search-token-v2 {
	margin: 2px;
	padding: 2px 6px;
	display: inline-block;
	border: 1px solid var(--color-grey-normal);
	border-radius: 4px;
	background-color: var(--color-grey-light);
	font-size: 14px;
	cursor: pointer;
	position: relative;
	transition: all 0.5s ease;
	margin-right: 8px;

	.close {
		transition: all 0.5s ease;
		position: absolute;
		height: 13px;
		width: 13px;
		right: -7px;
		top: -7px;
		font-size: 14px;
		text-align: center;
		background-color: var(--color-primary);
		color: #fff;
		border-radius: 50%;
		font-weight: 400;
		line-height: 1px;
		padding-bottom: 2px;
		opacity: 0;
	}

	&:hover {
		border: 1px solid var(--color-primary);
		background-color: var(--color-white);

		.close {
			opacity: 1;
		}
	}
}