.inner-banner {
	&.agb {
		background-image: url(RESOURCE/img/banner-agb.jpg);
	}

	&.impressum {
		background-image: url(RESOURCE/img/banner-impressum.jpg);
	}

	&.about {
		background-image: url(RESOURCE/img/banner-uberuns.jpg);
		background-position: center top;
	}

	&.privacy {
		background-image: url(RESOURCE/img/banner-datenschutz.jpg);
	}

	&.kontakt {
		background-image: url(RESOURCE/img/banner-kontakt.jpg);
		background-position: center center;
	}

	&.vermieter {
		background-image: url(RESOURCE/img/banner-vermieter.jpg);
		background-position: center center;
	}
}

.client-dt {
	display: flex;

	h4 {
		color: rgba(78, 66, 50, 1);
		font-weight: bold;
		margin-left: 15px;
	}
}